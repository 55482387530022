import Building from './pages/Building/Building';
import Floor from './pages/Floor/Floor';
import Meta from 'vue-meta';
import Router from 'vue-router';
import Vue from 'vue';

Vue.use(Router);
Vue.use(Meta, {
    refreshOnceOnNavigation: true
});
const el = document.getElementById('visual');
const data = {...el.dataset};

export default new Router({
    mode  : 'history',
    base  : process.env.NODE_ENV === 'production' ? data.baseUrl : '/', // eslint-disable-line no-process-env
    routes: [{
        path     : '*/',
        name     : 'complex',
        component: Building,
        meta     : {
            step: 'complex'
        }
    }, {
        path     : '*/area/:areaId',
        name     : 'area',
        component: Building,
        meta     : {
            step: 'area'
        }
    }, {
        path     : '*/area/:areaId/building/:buildingId',
        name     : 'areaBuilding',
        component: Building,
        meta     : {
            step: 'building'
        }
    }, {
        path     : '*/building/:buildingId',
        name     : 'building',
        component: Building,
        meta     : {
            step: 'building'
        }
    }, {
        path     : '*/area/:areaId/building/:buildingId/section/:sectionId',
        name     : 'areaBuildingSection',
        component: Building,
        meta     : {
            step: 'section'
        }
    }, {
        path     : '*/area/:areaId/section/:sectionId',
        name     : 'areaSection',
        component: Building,
        meta     : {
            step: 'section'
        }
    }, {
        path     : '*/building/:buildingId/section/:sectionId',
        name     : 'buildingSection',
        component: Building,
        meta     : {
            step: 'section'
        }
    }, {
        path     : '*/section/:sectionId',
        name     : 'section',
        component: Building,
        meta     : {
            step: 'section'
        }
    }, {
        path     : '*/area/:areaId/building/:buildingId/section/:sectionId/floor/:floorId',
        name     : 'areaBuildingSectionFloor',
        component: Floor,
        meta     : {
            step: 'floor'
        }
    }, {
        path     : '*/area/:areaId/building/:buildingId/floor/:floorId',
        name     : 'areaBuildingFloor',
        component: Floor,
        meta     : {
            step: 'floor'
        }
    }, {
        path     : '*/area/:areaId/section/:sectionId/floor/:floorId',
        name     : 'areaSectionFloor',
        component: Floor,
        meta     : {
            step: 'floor'
        }
    }, {
        path     : '*/area/:areaId/floor/:floorId',
        name     : 'areaFloor',
        component: Floor,
        meta     : {
            step: 'floor'
        }
    }, {
        path     : '*/building/:buildingId/section/:sectionId/floor/:floorId',
        name     : 'buildingSectionFloor',
        component: Floor,
        meta     : {
            step: 'floor'
        }
    }, {
        path     : '*/building/:buildingId/floor/:floorId',
        name     : 'buildingFloor',
        component: Floor,
        meta     : {
            step: 'floor'
        }
    }, {
        path     : '*/section/:sectionId/floor/:floorId',
        name     : 'sectionFloor',
        component: Floor,
        meta     : {
            step: 'floor'
        }
    }, {
        path     : '*/floor/:floorId',
        name     : 'floor',
        component: Floor,
        meta     : {
            step: 'floor'
        }
    }]
});
