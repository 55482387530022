<template>
    <div :class="['button-checkbox', modify]">
        <input
            type="checkbox"
            :id="name"
            :name="name"
            :checked="checked"
            :disabled="disabled"
            :value="value"
            class="button-checkbox__input"
            @change="$emit('change')">
        <label class="button-checkbox__label" :for="name">
            <span class="button-checkbox__text">{{ title }}</span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'ButtonCheckbox',

    props: {
        modify  : String,
        name    : [String, Number],
        value   : [String, Number],
        title   : [String, Number],
        checked : Boolean,
        disabled: Boolean
    }
};
</script>

<style lang="scss">
    @import "ButtonCheckbox";
</style>
