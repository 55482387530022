var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"visual-canvas__wrapper"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:start",value:(_vm.hideClue),expression:"hideClue",arg:"start"}],ref:"canvas",staticClass:"visual-canvas",class:[_vm.modify, ("visual-canvas_theme_" + (_vm.$dataset.propertyType)), {'is-scroll': _vm.isMini && _vm.isFloorStage }],on:{"click":_vm.hideClue}},[(_vm.isMini && _vm.isFloorStage)?_c('Zoom',[_c('svg',{ref:"svg",staticClass:"visual-canvas__svg",attrs:{"viewBox":[("0 0 " + (_vm.renderSize.width) + " " + (_vm.renderSize.height))],"preserveAspectRatio":"xMidYMid slice"},on:{"click":function($event){return _vm.removePanel($event)}}},[_c('image',{attrs:{"href":_vm.render.link,"x":"0","y":"0","width":"100%","height":"100%"}}),_vm._l((_vm.availableElements),function(element){return [(element.path instanceof Array)?_c('g',{key:element.id,attrs:{"id":element.id},on:{"mouseover":function($event){return _vm.mouseOverHandler(element.id, element.link, $event)},"mousemove":_vm.mouseMoveHandler,"mouseleave":_vm.mouseLeaveHandler,"click":function($event){_vm.isMini && _vm.isFloorStage ? _vm.openPopup(element) : _vm.clickHandler(element.link)}}},_vm._l((element.path),function(path,key){return _c('path',{key:("path-" + key),class:['visual-canvas__path',
                    {'is-booked': element.isBooked},
                    {'is-disabled': element.disabled},
                    {'is-hovered': _vm.idHoveredElement === element.id},
                    {'is-gold': _vm.isMini && _vm.isFloorStage}],attrs:{"d":path}})}),0):_c('path',{key:element.id,ref:"masks",refInFor:true,class:['visual-canvas__path',
                    {'is-booked': element.isBooked},
                    {'is-disabled': element.disabled},
                    {'is-hovered': _vm.idHoveredElement === element.id},
                    {'is-gold': _vm.isMini},
                    element.type && ("is-" + (element.type))],attrs:{"id":element.id,"d":element.path},on:{"mouseover":function($event){return _vm.mouseOverHandler(element.id, element.link, $event)},"mousemove":_vm.mouseMoveHandler,"mouseleave":_vm.mouseLeaveHandler,"click":function($event){_vm.isMini && _vm.isFloorStage ? _vm.openPopup(element) : _vm.clickHandler(element.link)}}})]}),_vm._l((_vm.renderPointers),function(renderPointer,index){return [(renderPointer.left && renderPointer.top)?_c('foreignObject',{key:("renderPointer-" + index),class:['visual-canvas__pointer'],attrs:{"x":renderPointer.left,"y":renderPointer.top,"width":"1","height":"1","requiredExtensions":"http://www.w3.org/1999/xhtml"}},[_c('div',{staticClass:"visual-canvas__pointer-wrapper",style:(!_vm.isSafari && ("transform: scale(" + _vm.pointerScale + ")")),attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('div',{staticClass:"visual-canvas__pointer-item visual-canvas__pointer-item_theme_render",attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('div',{staticClass:"visual-canvas__pointer-text"},[_vm._v(_vm._s(renderPointer.text))])])])]):_vm._e()]}),_vm._l((_vm.pointers),function(pointer){return [(pointer.left && pointer.top)?_c('foreignObject',{key:("pointer-" + (pointer.id)),class:['visual-canvas__pointer',
                                   {'is-hovered': _vm.idHoveredElement === pointer.id}],attrs:{"x":pointer.left,"y":pointer.top,"width":"1","height":"1","requiredExtensions":"http://www.w3.org/1999/xhtml"},on:{"mouseover":function($event){return _vm.mouseOverHandler(pointer.id, pointer.link, $event)},"mouseleave":_vm.mouseLeaveHandler,"click":function($event){return _vm.clickHandler(pointer.link)}}},[_c('div',{staticClass:"visual-canvas__pointer-wrapper",style:(!_vm.isSafari && ("transform: scale(" + _vm.pointerScale + ")")),attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('div',{class:['visual-canvas__pointer-item',
                                ("visual-canvas__pointer-item_type_" + (pointer.type)),
                                {'is-disabled': pointer.disabled},
                                {'is-hovered': pointer.isHovered}],attrs:{"xmlns":"http://www.w3.org/1999/xhtml"},on:{"click":function($event){return _vm.$emit('clickElement', pointer)}}},[_c('div',{staticClass:"visual-canvas__pointer-text"},[_vm._v(_vm._s(pointer.text))]),_c('div',{staticClass:"visual-canvas__pointer-plus"},[_c('IconPlus',{attrs:{"class-name":"visual-canvas__pointer-icon"}})],1)])])]):_vm._e()]}),_c('transition',{attrs:{"name":"fade"}},[(_vm.hoveredElement && _vm.hoveredElement.tooltip
                                   && !_vm.isMobile
                                   && _vm.dataStep.step !== 'floor'
                                   && !_vm.isMini)?_c('foreignObject',{staticClass:"visual-canvas__tooltip",attrs:{"x":Number(_vm.hoveredElement.pointer.left) + 20 || 0,"y":Number(_vm.hoveredElement.pointer.top) - 27 || 0,"width":"1","height":"1","requiredExtensions":"http://www.w3.org/1999/xhtml"}},[_c('div',{class:['visual-canvas__tooltip-wrapper', {'is-safari': _vm.isSafari}],style:(!_vm.isSafari && ("transform: scale(" + _vm.pointerScale + ")")),attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('TooltipContent',{attrs:{"data":_vm.hoveredElement.tooltip,"is-left":_vm.hoveredElement.pointer.left > _vm.renderSize.width - 360}}),(_vm.isFakePlus)?_c('div',{class:['visual-canvas__tooltip-plus', {'is-active': _vm.isFakePlus}]},[_c('IconPlus')],1):_vm._e()],1)]):_vm._e()])],2)]):_c('svg',{ref:"svg",staticClass:"visual-canvas__svg",attrs:{"viewBox":[("0 0 " + (_vm.renderSize.width) + " " + (_vm.renderSize.height))],"preserveAspectRatio":"xMidYMid slice"},on:{"click":function($event){return _vm.removePanel($event)}}},[_c('image',{attrs:{"href":_vm.render.link,"x":"0","y":"0","width":"100%","height":"100%"}}),_vm._l((_vm.availableElements),function(element){return [(element.path instanceof Array)?_c('g',{key:element.id,attrs:{"id":element.id},on:{"mouseover":function($event){return _vm.mouseOverHandler(element.id, element.link, $event)},"mousemove":_vm.mouseMoveHandler,"mouseleave":_vm.mouseLeaveHandler,"click":function($event){_vm.isMini && _vm.isFloorStage ? _vm.openPopup(element) : _vm.clickHandler(element.link)}}},_vm._l((element.path),function(path,key){return _c('path',{key:("path-" + key),class:['visual-canvas__path',
                            {'is-booked': element.isBooked},
                            {'is-disabled': element.disabled},
                            {'is-hovered': _vm.idHoveredElement === element.id},
                            {'is-gold': _vm.isMini && _vm.isFloorStage}],attrs:{"d":path}})}),0):_c('path',{key:element.id,ref:"masks",refInFor:true,class:['visual-canvas__path',
                            {'is-booked': element.isBooked},
                            {'is-disabled': element.disabled},
                            {'is-hovered': _vm.idHoveredElement === element.id},
                            {'is-gold': _vm.isMini && _vm.isFloorStage},
                            element.type && ("is-" + (element.type))],attrs:{"id":element.id,"d":element.path},on:{"mouseover":function($event){return _vm.mouseOverHandler(element.id, element.link, $event)},"mousemove":_vm.mouseMoveHandler,"mouseleave":_vm.mouseLeaveHandler,"click":function($event){_vm.isMini && _vm.isFloorStage ? _vm.openPopup(element) : _vm.clickHandler(element.link)}}})]}),_vm._l((_vm.renderPointers),function(renderPointer,index){return [(renderPointer.left && renderPointer.top)?_c('foreignObject',{key:("renderPointer-" + index),class:['visual-canvas__pointer'],attrs:{"x":renderPointer.left,"y":renderPointer.top,"width":"1","height":"1","requiredExtensions":"http://www.w3.org/1999/xhtml"}},[_c('div',{staticClass:"visual-canvas__pointer-wrapper",style:(!_vm.isSafari && ("transform: scale(" + _vm.pointerScale + ")")),attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('div',{class:['visual-canvas__pointer-item', 'visual-canvas__pointer-item_theme_render'],attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('div',{staticClass:"visual-canvas__pointer-text"},[_vm._v(_vm._s(renderPointer.text))])])])]):_vm._e()]}),_vm._l((_vm.pointers),function(pointer){return [(pointer.left && pointer.top)?_c('foreignObject',{key:("pointer-" + (pointer.id)),class:['visual-canvas__pointer',
                               {'is-hovered': _vm.idHoveredElement === pointer.id}],attrs:{"x":pointer.left,"y":pointer.top,"width":"1","height":"1","requiredExtensions":"http://www.w3.org/1999/xhtml"},on:{"mouseover":function($event){return _vm.mouseOverHandler(pointer.id, pointer.link, $event)},"mouseleave":_vm.mouseLeaveHandler,"click":function($event){return _vm.clickHandler(pointer.link)}}},[_c('div',{staticClass:"visual-canvas__pointer-wrapper",style:(!_vm.isSafari && ("transform: scale(" + _vm.pointerScale + ")")),attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('div',{class:['visual-canvas__pointer-item',
                                        ("visual-canvas__pointer-item_type_" + (pointer.type)),
                                        {'is-disabled': pointer.disabled},
                                        {'is-hovered': pointer.isHovered}],attrs:{"xmlns":"http://www.w3.org/1999/xhtml"},on:{"click":function($event){return _vm.$emit('clickElement', pointer)}}},[_c('div',{staticClass:"visual-canvas__pointer-text"},[_vm._v(_vm._s(pointer.text))]),_c('div',{staticClass:"visual-canvas__pointer-plus"},[_c('IconPlus',{attrs:{"class-name":"visual-canvas__pointer-icon"}})],1)])])]):_vm._e()]}),_c('transition',{attrs:{"name":"fade"}},[(_vm.hoveredElement && _vm.hoveredElement.tooltip
                               && !_vm.isMobile
                               && _vm.dataStep.step !== 'floor'
                               && !_vm.isMini)?_c('foreignObject',{staticClass:"visual-canvas__tooltip",attrs:{"x":Number(_vm.hoveredElement.pointer.left) || 0,"y":Number(_vm.hoveredElement.pointer.top) || 0,"width":"1","height":"1","requiredExtensions":"http://www.w3.org/1999/xhtml"}},[_c('div',{class:['visual-canvas__tooltip-wrapper', {'is-safari': _vm.isSafari}],style:(!_vm.isSafari && ("transform: scale(" + _vm.pointerScale + ") translate(24px, -32px)")),attrs:{"xmlns":"http://www.w3.org/1999/xhtml"}},[_c('TooltipContent',{attrs:{"data":_vm.hoveredElement.tooltip,"is-left":_vm.hoveredElement.pointer.left > _vm.renderSize.width - 360}}),(_vm.isFakePlus)?_c('div',{class:['visual-canvas__tooltip-plus', {'is-active': _vm.isFakePlus}]},[_c('IconPlus')],1):_vm._e()],1)]):_vm._e()])],2)],1),_c('ScrollPosition',{on:{"moveIndicator":_vm.hideClue}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }