<template>
    <div :class="['visual-wrapper', {'is-show': isReady}]">
        <Canvas ref="canvas"
                :is-mobile="isMobile"
                :is-touch="isTouch"/>
        <Compass/>
        <Rotate :is-mobile="isMobile"/>
        <Filters :is-mobile="isMobile"/>
        <Panel :is-mobile="isMobile"/>
        <transition name="fade">
            <TouchClue v-if="isMobileClue"/>
        </transition>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Canvas from '../../components/Canvas/Canvas';
import Compass from '../../components/Compass/Compass';
import Filters from '../../components/Filters/Filters';
import Panel from '../../components/Panel/Panel';
import Rotate from '../../components/Rotate/Rotate';
import store from '../../store';
import TouchClue from '../../components/TouchClue/TouchClue';

export default {
    name: 'Building',

    components: {
        Canvas,
        Compass,
        Filters,
        Panel,
        Rotate,
        TouchClue
    },

    props: {
        isMobile: Boolean,
        isTouch : Boolean
    },

    computed: {
        ...mapGetters({
            isMobileClue: 'GET_IS_MOBILE_CLUE',
            isReady     : 'GET_IS_READY'
        })
    },

    beforeRouteEnter(to, from, next) {
        /**
         * Отправляет данные на сервер.
         */
        const body = {
            step: to.meta.step,
            ...to.params.buildingId && {
                buildingId: to.params.buildingId
            },
            ...to.params.sectionId && {
                sectionId: to.params.sectionId
            },
            ...to.params
        };

        store.dispatch('send', {
            body,
            query: to.query
        }).then(() => {
            next();
        });
    },

    methods: {
        ...mapActions(['send'])
    }
};
</script>
