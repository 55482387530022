<template>
    <div :class="['popup', {'popup_state_open': isOpen}]">
        <div class="popup__overlay" @click="hidePopup"></div>
        <div class="popup__container">
            <div class="popup__wrapper booking-popup">
                <div class="popup__inner">
                    <button class="popup__close" aria-label="Закрыть" @click="hidePopup"></button>
                    <div class="popup__inner-wrapper">
                        <div class="popup__content" v-if="element">
                            <div class="booking-popup__content">
                                <div class="booking-popup__title"
                                     v-if="element.title"
                                     v-html="element.title" />
                                <div class="booking-popup__description"
                                     v-if="element.description"
                                     v-html="element.description" />
                                <ul class="booking-popup__list">
                                    <li class="booking-popup__list-item"
                                        v-if="element.area">
                                        <span>Площадь</span>
                                        <span v-html="element.area" />
                                    </li>
                                    <li class="booking-popup__list-item"
                                        v-if="element.quarterShort">
                                        <span>Квартал</span>
                                        <span v-html="element.quarterShort" />
                                    </li>
                                </ul>

                                <div class="booking-popup__price">
                                    <span v-if="element.hidePrice">По&nbsp;запросу</span>
                                    <template v-else>
                                        <span v-if="element.price && element.price.current">
                                            {{ convertPriceDigit(element.price.current) }} ₽
                                        </span>
                                        <span v-if="element.price && element.price.mortgage"
                                              class="booking-popup__mortgage">
                                            от {{ convertPriceDigit(element.price.mortgage) }} ₽ в месяц в ипотеку
                                        </span>
                                    </template>
                                </div>

                                <div v-if="element.linkText"
                                     class="booking-popup__action">
                                    <button type="button"
                                            class="button button_width_block button_theme_small"
                                            @click="goBooking">
                                        <span class="button__inner">
                                            <span class="button__text"
                                                  v-html="element.linkText"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Observer from 'common/scripts/observer';
import {Utils} from 'common/scripts/utils';

const observer = new Observer();

export default {
    name: 'BookingPopup',

    data() {
        return {
            isOpen : false,
            element: null
        };
    },

    mounted() {
        observer.subscribe('openBookingPopup', (data) => {
            this.setData(data);
            this.openPopup();
        });
    },

    beforeDestroy() {
        observer.unsubscribe('openBookingPopup');
    },

    methods: {
        setData(data) {
            this.element = data.tooltip || null;
        },

        openPopup() {
            this.isOpen = true;
        },

        hidePopup() {
            this.isOpen = false;
        },

        goBooking() {
            const comagic = window.Comagic || false;
            const comagicWidgetId = 1551;
            const comagicWidgetIdMobile = 1552;

            if (!comagic) {
                return;
            }

            if (Utils.isMobile()) {
                comagic.openWidget(comagicWidgetIdMobile);

                return;
            }

            comagic.openWidget(comagicWidgetId);
        },

        convertPriceDigit(number) {
            return Utils.convertToDigit(number);
        }
    }
};
</script>

<style lang="scss">
@import "BookingPopup";
</style>
