<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         :class="className">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8.93945L16.5303 13.4698C16.8232 13.7627 16.8232 14.2375 16.5303 14.5304C16.2374 14.8233 15.7626 14.8233 15.4697 14.5304L12 11.0608L8.53033 14.5304C8.23744 14.8233 7.76256 14.8233 7.46967 14.5304C7.17678 14.2375 7.17678 13.7627 7.46967 13.4698L12 8.93945Z"/>
    </svg>
</template>

<script>
export default {
    name: 'IconArrowTop',

    props: {
        className: String
    }
};
</script>
