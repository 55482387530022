<template>
    <div xmlns="http://www.w3.org/1999/xhtml"
         :class="['visual-canvas__tooltip-content', `visual-canvas__tooltip-content_theme_${dataStep.step}`,
         {'visual-canvas__tooltip-content_dir_left': isLeft}]"
         :style="{transform: [isLeft ? `translateX(-100%)` : ``]}" >
        <!-- Тултип корпусов -->
        <template>
            <div class="visual-canvas__tooltip-header">
                <div :class="['visual-canvas__tooltip-title', data.modify ? data.modify : '']">
                    {{ data.title }}
                </div>
                <div v-if="data.comment"
                     class="visual-canvas__tooltip-comment"
                     v-html="data.comment"></div>
                <div v-if="data.building"
                     class="visual-canvas__tooltip-comment"
                     v-html="data.building"></div>
                <div v-if="data.quarter"
                     class="visual-canvas__tooltip-comment"
                     v-html="data.quarter"></div>
                <div v-if="data.deadline"
                     class="visual-canvas__tooltip-comment"
                     v-html="data.deadline"></div>
            </div>
            <ul v-if="data.params.length"
                class="visual-canvas__tooltip-params">
                <li class="visual-canvas__tooltip-param visual-canvas__tooltip-param_theme_title">
                    <div class="visual-canvas__param-type">Спален</div>
                    <div class="visual-canvas__param-value">Стоимость</div>
                    <div class="visual-canvas__param-amount">В продаже</div>
                </li>
                <li v-for="(item, index) in data.params"
                    :key="index"
                    class="visual-canvas__tooltip-param">
                    <div v-if="item.title"
                         class="visual-canvas__param-type">{{ rooms(item.title) }}</div>
                    <div v-if="data.hidePrice || item.hidePrice"
                         class="visual-canvas__param-value"
                         v-html="'По&nbsp;запросу'"></div>
                    <div v-else-if="item.price"
                         class="visual-canvas__param-value"
                         v-html="`от&nbsp;${convertPriceRank(item.price)}&nbsp;млн&nbsp;₽`"></div>
                    <div v-if="item.amount"
                         class="visual-canvas__param-amount"
                         v-html="item.amount"></div>
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {Utils} from 'common/scripts/utils';

export default {
    name: 'TooltipContent',

    props: {
        isLeft: Boolean,
        data  : Object
    },

    data() {
        return {
            windowWidth: Utils.getWindowWidth()
        };
    },

    computed: {
        ...mapGetters({
            dataStep  : 'GET_DATA_STEP',
            renderSize: 'GET_CANVAS_SIZE'
        }),

        scale() {
            return this.renderSize.width / this.windowWidth;
        }
    },

    mounted() {
        this._bindEvents();
    },

    beforeDestroy() {
        const events = ['resize', 'orientationchange'];

        events.forEach((event) => {
            window.removeEventListener(event, this._onResize, false);
        });
    },

    methods: {
        _bindEvents() {
            const events = ['resize', 'orientationchange'];

            events.forEach((event) => {
                window.addEventListener(event, this._onResize.bind(this));
            });
        },

        _onResize() {
            this.windowWidth = Utils.getWindowWidth();
        },

        convertPriceRank(price) {
            return Utils.convertToDigit(Utils.convertToRank(price, 1000000).toFixed(2));
        },

        rooms(title) {
            return parseInt(title);
        }
    }
};
</script>
