<template>
    <transition name="fade">
        <div v-if="state"
             class="visual-preloader">
            <div class="visual-loader">
                <div class="visual-loader__item"></div>
                <div class="visual-loader__item"></div>
                <div class="visual-loader__item"></div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Preloader',

    computed: {
        ...mapGetters({
            state: 'GET_IS_PROCESSING'
        })
    }
};
</script>

<style lang="scss">
    @import "Preloader.scss";
</style>
