<template >
    <div class="visual">
        <Breadcrumbs v-if="mode !== 'minimal'" />
        <router-view :is-mobile="isMobile"
                     :is-touch="isTouch"/>
        <Preloader/>
        <Tooltip v-if="dataStep.step === 'floor' || isMini"/>
    </div>
</template>

<script>
import {
    DESKTOP_BREAKPOINT,
    TABLET_BREAKPOINT
} from 'common/scripts/constants';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import {mapGetters} from 'vuex';
import Preloader from './components/Preloader/Preloader.vue';
import Tooltip from './components/Tooltip/Tooltip';
import {Utils} from 'common/scripts/utils';

export default {
    name: 'App',

    metaInfo() {
        return this.metadata ?
            {
                title        : this.metadata.title,
                titleTemplate: this.metadata.title,
                htmlAttrs    : {
                    lang: 'ru',
                    amp : true
                },
                meta: [{
                    property: 'og:title',
                    content : this.metadata.title,
                    vmid    : 'og:title'
                }, {
                    property: 'og:description',
                    content : this.metadata.description,
                    vmid    : 'og:description'
                }, {
                    name   : 'description',
                    content: this.metadata.description,
                    vmid   : 'description'
                }]
            } :
            {};
    },

    components: {
        Breadcrumbs,
        Preloader,
        Tooltip
    },

    data() {
        return {
            windowWidth: Utils.getWindowWidth(),
            isTouched  : Utils.isTouch()
        };
    },

    computed: {
        ...mapGetters({
            dataStep   : 'GET_DATA_STEP',
            isMini     : 'GET_IS_MINI',
            breadcrumbs: 'GET_BREADCRUMBS',
            metadata   : 'GET_METADATA',
            mode       : 'GET_MODE',
            link       : 'GET_LINK'
        }),

        isMobile() {
            return this.windowWidth < TABLET_BREAKPOINT;
        },

        isTouch() {
            return this.isTouched && this.windowWidth >= TABLET_BREAKPOINT && this.windowWidth < DESKTOP_BREAKPOINT;
        }
    },

    mounted() {
        const events = ['resize', 'orientationchange'];

        events.forEach((event) => {
            window.addEventListener(event, this._onResize.bind(this));
        });
    },

    beforeDestroy() {
        const events = ['resize', 'orientationchange'];

        events.forEach((event) => {
            window.removeEventListener(event, this._onResize, false);
        });
    },

    methods: {
        _onResize() {
            this.windowWidth = Utils.getWindowWidth();
        }
    }
};
</script>

<style lang="scss">
    @import "./App.scss";
</style>
