<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         :class="className">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 6.5C6.17157 6.5 5.5 7.17157 5.5 8C5.5 8.82843 6.17157 9.5 7 9.5C7.82843 9.5 8.5 8.82843 8.5 8C8.5 7.17157 7.82843 6.5 7 6.5ZM4 8C4 6.34315 5.34315 5 7 5C8.65685 5 10 6.34315 10 8C10 9.65685 8.65685 11 7 11C5.34315 11 4 9.65685 4 8Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 14.5C16.1716 14.5 15.5 15.1716 15.5 16C15.5 16.8284 16.1716 17.5 17 17.5C17.8284 17.5 18.5 16.8284 18.5 16C18.5 15.1716 17.8284 14.5 17 14.5ZM14 16C14 14.3432 15.3432 13 17 13C18.6568 13 20 14.3432 20 16C20 17.6568 18.6568 19 17 19C15.3432 19 14 17.6568 14 16Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 8C9.25 7.58579 9.58579 7.25 10 7.25H19C19.4142 7.25 19.75 7.58579 19.75 8C19.75 8.41421 19.4142 8.75 19 8.75H10C9.58579 8.75 9.25 8.41421 9.25 8Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 16C4.25 15.5858 4.58579 15.25 5 15.25H14C14.4142 15.25 14.75 15.5858 14.75 16C14.75 16.4142 14.4142 16.75 14 16.75H5C4.58579 16.75 4.25 16.4142 4.25 16Z"/>
    </svg>
</template>

<script>
export default {
    name: 'IconFilter',

    props: {
        className: String
    }
};
</script>
