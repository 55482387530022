<template>
    <div :class="['visual-scroll', {'visual-scroll_page_floor': dataStep.step === 'floor'}]">
        <div ref="movePosition"
             class="visual-scroll__element">
            <button ref="moveIndicator"
                    class="visual-scroll__slider"
                    :style="`left: ${indicatorPosition}px`"
                    v-touch:start="_onTouchStart"
                    v-touch:end="_onTouchEnd">
                <IconArrowLeft class-name="visual-scroll__slider-arrow"/>
                <IconArrowLeft class-name="visual-scroll__slider-arrow visual-scroll__slider-arrow_dir_right"/>
            </button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import IconArrowLeft from '../../components/Icons/icon-arrow-left';

export default {
    name: 'ScrollPosition',

    components: {
        IconArrowLeft
    },

    data() {
        return {
            indicatorDragStart: null,
            indicatorPosition : null
        };
    },

    computed: {
        ...mapGetters({
            dataStep   : 'GET_DATA_STEP',
            canvasShift: 'GET_CANVAS_SHIFT'
        })
    },

    watch: {
        canvasShift() {
            this._setIndicatorPosition();
        }
    },

    mounted() {
        this._setIndicatorPosition();
    },

    methods: {
        ...mapActions(['changeCanvasShift']),

        _setIndicatorPosition() {
            this.indicatorPosition = this.$refs.movePosition ?
                this.canvasShift * (this.$refs.movePosition.clientWidth - this.$refs.moveIndicator.clientWidth) :
                '';
        },

        _onTouchStart(event) {
            this.indicatorDragStart = event.touches[0].clientX;
            document.addEventListener('touchmove', this._moveIndicator);
        },

        _onTouchEnd() {
            document.removeEventListener('touchmove', this._moveIndicator);
        },

        _moveIndicator(event) {
            const scrollWidth = this.$refs.movePosition.clientWidth - this.$refs.moveIndicator.clientWidth;
            const delta = (event.touches[0].clientX - this.indicatorDragStart) / scrollWidth;
            const newImgDragPercent = Math.min(Math.max(this.canvasShift + delta, 0), 1);
            const movePositionSize = this.$refs.movePosition.getBoundingClientRect();
            const movePositionLeft = movePositionSize.left;
            const movePositionWidth = movePositionSize.width;

            this.indicatorDragStart = Math.min(Math.max(event.touches[0].clientX, movePositionLeft),
                movePositionLeft + movePositionWidth);
            this.changeCanvasShift(newImgDragPercent);
            this.$emit('moveIndicator');
        }
    }
};
</script>

<style lang="scss">
    @import "ScrollPosition";
</style>
