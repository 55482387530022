<template>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"
         :class="className">
        <path d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#003930" fill-opacity="0.2"/>
        <path d="M25.0783 11.3738C25.2092 11.3299 25.2797 11.1882 25.2358 11.0573C25.1919 10.9264 25.0502 10.8559 24.9193 10.8998L25.0783 11.3738ZM10.8988 24.9203C10.8549 25.0512 10.9254 25.1929 11.0563 25.2368C11.1873 25.2807 11.329 25.2102 11.3729 25.0793L10.8988 24.9203ZM11.3729 38.9203C11.329 38.7894 11.1873 38.7188 11.0563 38.7628C10.9254 38.8067 10.8549 38.9484 10.8988 39.0793L11.3729 38.9203ZM24.9193 53.0998C25.0502 53.1437 25.1919 53.0731 25.2358 52.9422C25.2797 52.8113 25.2092 52.6696 25.0783 52.6257L24.9193 53.0998ZM38.9193 52.6257C38.7884 52.6696 38.7179 52.8113 38.7618 52.9422C38.8057 53.0731 38.9474 53.1437 39.0783 53.0998L38.9193 52.6257ZM53.0988 39.0793C53.1427 38.9484 53.0722 38.8067 52.9412 38.7628C52.8103 38.7188 52.6686 38.7894 52.6247 38.9203L53.0988 39.0793ZM52.6247 25.0793C52.6686 25.2102 52.8103 25.2807 52.9412 25.2368C53.0722 25.1929 53.1427 25.0512 53.0988 24.9203L52.6247 25.0793ZM39.0783 10.8998C38.9474 10.8559 38.8057 10.9264 38.7618 11.0573C38.7179 11.1882 38.7884 11.3299 38.9193 11.3738L39.0783 10.8998ZM11.3729 25.0793C13.5345 18.634 18.6331 13.5355 25.0783 11.3738L24.9193 10.8998C18.3255 13.1113 13.1103 18.3265 10.8988 24.9203L11.3729 25.0793ZM25.0783 52.6257C18.6331 50.4641 13.5345 45.3655 11.3729 38.9203L10.8988 39.0793C13.1103 45.6731 18.3255 50.8883 24.9193 53.0998L25.0783 52.6257ZM52.6247 38.9203C50.4631 45.3655 45.3645 50.4641 38.9193 52.6257L39.0783 53.0998C45.6721 50.8883 50.8873 45.6731 53.0988 39.0793L52.6247 38.9203ZM38.9193 11.3738C45.3645 13.5355 50.4631 18.634 52.6247 25.0793L53.0988 24.9203C50.8873 18.3265 45.6721 13.1113 39.0783 10.8998L38.9193 11.3738Z" fill="white"/>
        <path d="M31.5525 18.3683L28 31.9994H30C30.0003 30.9402 31.0534 30 32 30C32.9466 30 33.9997 30.9402 34 31.9994H36L32.4475 18.3683C32.3195 17.8772 31.6805 17.8772 31.5525 18.3683Z" fill="white"/>
        <path d="M32.1559 13.2497C31.4626 13.2497 30.8359 13.0897 30.2759 12.7697C29.7226 12.443 29.2893 11.9964 28.9759 11.4297C28.6626 10.8564 28.5059 10.2097 28.5059 9.48971C28.5059 8.76971 28.6593 8.12304 28.9659 7.54971C29.2793 6.96971 29.7126 6.51638 30.2659 6.18971C30.8259 5.86304 31.4559 5.69971 32.1559 5.69971C32.6759 5.69971 33.1693 5.80971 33.6359 6.02971C34.1093 6.24971 34.5093 6.55638 34.8359 6.94971C35.1626 7.33638 35.3859 7.77304 35.5059 8.25971L34.6159 8.37971C34.5293 8.01971 34.3626 7.69638 34.1159 7.40971C33.8759 7.12304 33.5826 6.89971 33.2359 6.73971C32.8959 6.57971 32.5359 6.49971 32.1559 6.49971C31.6293 6.49971 31.1559 6.62971 30.7359 6.88971C30.3226 7.14304 29.9993 7.49638 29.7659 7.94971C29.5326 8.39638 29.4159 8.90304 29.4159 9.46971C29.4159 10.0364 29.5326 10.5464 29.7659 10.9997C30.0059 11.453 30.3326 11.8097 30.7459 12.0697C31.1659 12.323 31.6359 12.4497 32.1559 12.4497C32.5293 12.4497 32.8859 12.373 33.2259 12.2197C33.5659 12.0597 33.8593 11.843 34.1059 11.5697C34.3526 11.2897 34.5259 10.9797 34.6259 10.6397L35.4959 10.8097C35.3826 11.2697 35.1626 11.6864 34.8359 12.0597C34.5093 12.433 34.1093 12.7264 33.6359 12.9397C33.1693 13.1464 32.6759 13.2497 32.1559 13.2497Z" fill="white"/>
        <path d="M51.4619 27.9502H54.5319C54.9586 27.9502 55.3386 28.0335 55.6719 28.2002C56.0119 28.3602 56.2786 28.5835 56.4719 28.8702C56.6653 29.1569 56.7619 29.4802 56.7619 29.8402C56.7619 30.1735 56.6653 30.4769 56.4719 30.7502C56.2786 31.0235 56.0319 31.2069 55.7319 31.3002V31.4002C56.0919 31.5002 56.3853 31.7002 56.6119 32.0002C56.8453 32.3002 56.9619 32.6402 56.9619 33.0202C56.9619 33.3935 56.8553 33.7335 56.6419 34.0402C56.4353 34.3402 56.1453 34.5769 55.7719 34.7502C55.4053 34.9169 54.9886 35.0002 54.5219 35.0002H51.4619V27.9502ZM54.4219 30.9802C54.8419 30.9802 55.1853 30.8769 55.4519 30.6702C55.7253 30.4635 55.8619 30.1969 55.8619 29.8702C55.8619 29.5435 55.7253 29.2769 55.4519 29.0702C55.1853 28.8569 54.8419 28.7502 54.4219 28.7502H52.3719V30.9802H54.4219ZM54.4019 34.2002C54.7153 34.2002 54.9986 34.1469 55.2519 34.0402C55.5053 33.9335 55.7019 33.7869 55.8419 33.6002C55.9886 33.4135 56.0619 33.2035 56.0619 32.9702C56.0619 32.6235 55.9053 32.3402 55.5919 32.1202C55.2786 31.8935 54.8819 31.7802 54.4019 31.7802H52.3719V34.2002H54.4019Z" fill="white"/>
        <path d="M33.1993 57.2397C32.5726 57.2397 32.0093 57.0997 31.5093 56.8197C31.0159 56.5397 30.6159 56.1464 30.3093 55.6397C30.0093 55.1264 29.8326 54.533 29.7793 53.8597H28.6993V56.9997H27.7793V49.9497H28.6993V53.0597H29.7793C29.8259 52.393 29.9993 51.8064 30.2993 51.2997C30.6059 50.7864 31.0093 50.393 31.5093 50.1197C32.0093 49.8397 32.5726 49.6997 33.1993 49.6997C33.8659 49.6997 34.4593 49.8564 34.9793 50.1697C35.5059 50.483 35.9159 50.9264 36.2093 51.4997C36.5026 52.0664 36.6493 52.723 36.6493 53.4697C36.6493 54.223 36.5026 54.8864 36.2093 55.4597C35.9159 56.0264 35.5059 56.4664 34.9793 56.7797C34.4593 57.0864 33.8659 57.2397 33.1993 57.2397ZM33.1993 56.4397C33.6926 56.4397 34.1293 56.3197 34.5093 56.0797C34.8959 55.833 35.1959 55.4864 35.4093 55.0397C35.6293 54.5864 35.7393 54.063 35.7393 53.4697C35.7393 52.8764 35.6293 52.3564 35.4093 51.9097C35.1959 51.4564 34.8959 51.1097 34.5093 50.8697C34.1293 50.623 33.6926 50.4997 33.1993 50.4997C32.7126 50.4997 32.2759 50.623 31.8893 50.8697C31.5093 51.1097 31.2093 51.4564 30.9893 51.9097C30.7759 52.3564 30.6693 52.8764 30.6693 53.4697C30.6693 54.063 30.7759 54.5864 30.9893 55.0397C31.2093 55.4864 31.5093 55.833 31.8893 56.0797C32.2759 56.3197 32.7126 56.4397 33.1993 56.4397Z" fill="white"/>
        <path d="M10.1154 35.2502C9.55539 35.2502 9.05872 35.1402 8.62539 34.9202C8.19872 34.6935 7.86539 34.3802 7.62539 33.9802C7.39206 33.5802 7.27539 33.1269 7.27539 32.6202H8.16539C8.16539 32.9735 8.24539 33.2902 8.40539 33.5702C8.57206 33.8435 8.79872 34.0602 9.08539 34.2202C9.37872 34.3735 9.71539 34.4502 10.0954 34.4502C10.4354 34.4502 10.7387 34.3935 11.0054 34.2802C11.2787 34.1602 11.492 33.9969 11.6454 33.7902C11.8054 33.5769 11.8854 33.3369 11.8854 33.0702C11.8854 32.6769 11.7254 32.3569 11.4054 32.1102C11.092 31.8569 10.692 31.7302 10.2054 31.7302H9.30539V30.9302H10.2554C10.662 30.9302 10.9987 30.8169 11.2654 30.5902C11.5387 30.3635 11.6754 30.0769 11.6754 29.7302C11.6754 29.3702 11.522 29.0769 11.2154 28.8502C10.9087 28.6169 10.522 28.5002 10.0554 28.5002C9.53539 28.5002 9.11539 28.6502 8.79539 28.9502C8.47539 29.2435 8.31539 29.6235 8.31539 30.0902H7.42539C7.42539 29.6302 7.53539 29.2202 7.75539 28.8602C7.97539 28.4935 8.28206 28.2102 8.67539 28.0102C9.07539 27.8035 9.53539 27.7002 10.0554 27.7002C10.5287 27.7002 10.9554 27.7835 11.3354 27.9502C11.722 28.1169 12.0254 28.3502 12.2454 28.6502C12.4654 28.9435 12.5754 29.2802 12.5754 29.6602C12.5754 30.0535 12.4654 30.3935 12.2454 30.6802C12.0254 30.9602 11.732 31.1469 11.3654 31.2402V31.3402C11.7854 31.4202 12.1287 31.6302 12.3954 31.9702C12.6687 32.3035 12.8054 32.6935 12.8054 33.1402C12.8054 33.5402 12.6887 33.9002 12.4554 34.2202C12.222 34.5402 11.8987 34.7935 11.4854 34.9802C11.0787 35.1602 10.622 35.2502 10.1154 35.2502Z" fill="white"/>
        <path d="M32 33C32.5523 33 33 32.5523 33 32C33 31.4477 32.5523 31 32 31C31.4477 31 31 31.4477 31 32C31 32.5523 31.4477 33 32 33Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: 'IconCompass',

    props: {
        className: String
    }
};
</script>
