<template>
    <transition name="fade">
        <div v-if="isDoubleRotate && rotate"
             class="visual-rotate">
            <button :class="['visual-rotate__button']"
                    @click="changeRotate">
                <IconRotate class-name="visual-rotate__icon"/>
            </button>
        </div>
    </transition>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import IconRotate from '../Icons/icon-rotate';

export default {
    name: 'Rotate',

    components: {
        IconRotate
    },

    props: {
        isMobile: Boolean
    },

    computed: {
        ...mapGetters({
            isMobileClue: 'GET_IS_MOBILE_CLUE',
            perspectives: 'GET_PERSPECTIVES',
            rotate      : 'GET_ROTATE'
        }),

        isDoubleRotate() {
            return this.perspectives && this.perspectives.length === 2;
        }
    },

    methods: {
        ...mapActions(['changeMobileClue', 'send']),

        changeRotate() {
            if (this.isMobile && this.isMobileClue) {
                this.changeMobileClue(false);
            }

            const rotateId = this.perspectives.find((item) => {
                return !item.active;
            }).id;

            const query = {
                ...this.$route.query,
                rotateId
            };

            this.$router.push({query});
            this._sendRequest();
        },

        /* Отправка запроса при смене ракурса */
        _sendRequest() {
            const body = {
                step: this.$route.meta.step,
                ...this.$route.params.buildingId && {
                    buildingId: this.$route.params.buildingId
                },
                ...this.$route.params.sectionId && {
                    sectionId: this.$route.params.buildingId
                },
                ...this.$route.params
            };

            this.send({
                body,
                query: this.$route.query
            });
        }
    }
};
</script>

<style lang="scss">
    @import "Rotate.scss";
</style>
