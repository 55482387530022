<template>
    <div ref="sly"
         v-if="reverseFloors && reverseFloors.length"
         class="visual-sly">
        <div class="visual-sly__title">Этаж</div>
        <div class="visual-sly__container">
            <button ref="sly_prev"
                    v-if="reverseFloors.length > 5"
                    class="visual-sly__arrow visual-sly__arrow_dis_prev">
                <IconArrowTop/>
            </button>

            <div ref="list"
                 class="visual-sly__wrap">
                <ul class="visual-sly__list">
                    <li ref="list_item"
                        v-for="floor in reverseFloors"
                        :key="floor.id"
                        :class="['visual-sly__item', {'is-disabled':floor.disabled},
                            {'is-active': currentFloorId === Number(floor.id)}]"
                        @click="setFloor(floor.id)">
                        <button :class="['visual-sly__button', {'is-active': currentFloorId === Number(floor.id)}]">
                            {{floor.title}}
                        </button>
                    </li>
                </ul>
            </div>

            <button ref="sly_next"
                    v-if="reverseFloors.length > 5"
                    class="visual-sly__arrow visual-sly__arrow_dis_next">
                <IconArrowTop/>
            </button>
        </div>
    </div>
</template>

<script>
import './dist/sly';
import {mapActions, mapGetters} from 'vuex';
import IconArrowTop from '../Icons/icon-arrow-top';

export default {
    name: 'Sly',

    components: {
        IconArrowTop
    },

    props: {
        isMobile: Boolean
    },

    data() {
        return {
            sly: null
        };
    },

    computed: {
        ...mapGetters({
            filters: 'GET_FILTERS'
        }),

        reverseFloors() {
            return this.filters.floor && Object.values(this.filters.floor).reverse();
        },

        currentFloorId() {
            return Number(this.$route.params.floorId);
        },

        currentFloor() {
            return this.reverseFloors.find((item) => {
                return Number(item.id) === Number(this.currentFloorId);
            });
        }
    },

    watch: {
        filters() {
            this.$nextTick(() => {
                this.sly.reload();
            });
        }
    },

    mounted() {
        this._initSly();
    },

    beforeDestroy() {
        if (this.sly) {
            this.sly.destroy();
        }
    },

    methods: {
        ...mapActions(['send']),

        _initSly() {
            const slySelector = this.$refs.sly;

            if (!slySelector) {
                return;
            }

            const slyListSelector = this.$refs.list;
            const slyItemsLength = this.$refs.list_item.length;

            const defaultItemsNumber = 5;

            // Расчет ширины контейнера если элементов меньше 8
            if (slyItemsLength < defaultItemsNumber) {
                slyListSelector.style.height = '100%';
            }

            const Sly = window.Sly;
            const startAt = this.reverseFloors.indexOf(this.currentFloor);

            const options = {
                horizontal    : false,
                itemNav       : 'basic',
                smart         : 1,
                activateOn    : 'click',
                activateMiddle: 1,
                mouseDragging : 1,
                touchDragging : 1,
                releaseSwing  : 1,
                startAt,
                scrollBy      : 1,
                speed         : 300,
                dragHandle    : 1,
                dynamicHandle : 1,
                clickBar      : 1,
                prevPage      : this.$refs.sly_prev,
                nextPage      : this.$refs.sly_next
            };

            this.sly = new Sly(slyListSelector, options, {
                load: () => {
                    slySelector.style.opacity = 1;
                }
            });

            this.sly.init();
        },

        setFloor(floorId) {
            if (Number(floorId) === this.currentFloorId) {
                return;
            }

            const url = this.$route.path.replace(/floor[/]\d*/, `floor/${floorId}`);
            const body = {
                step: this.$route.meta.step,
                ...this.$route.params.buildingId && {
                    buildingId: this.$route.params.buildingId
                },
                ...this.$route.params.sectionId && {
                    sectionId: this.$route.params.sectionId
                },
                floorId,
                ...this.$dataset
            };

            this.$router.replace({
                path : url,
                query: this.$route.query
            });

            this.send({body});
            if (this.isMobile) {
                this.$emit('closeFilter');
            }
        }
    }
};
</script>

<style lang="scss">
    @import "Sly.scss";
</style>
