<template>
    <div class="visual-compass">
        <IconCompass :style="{transform: `rotate(${compass.degree}deg)`}"
                     class-name="visual-compass__svg" />
    </div>
</template>

<script>
import IconCompass from '../Icons/icon-compass';
import {mapGetters} from 'vuex';

export default {
    name: 'Compass',

    components: {
        IconCompass
    },

    computed: {
        ...mapGetters({
            compass: 'GET_COMPASS'
        })
    }
};
</script>

<style lang="scss">
    @import "Compass";
</style>
