<template>
    <svg :class="className" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2632 1.28857C15.6562 1.15778 16.0808 1.37037 16.2116 1.76339L17.586 5.8935L13.8212 7.67793C13.4469 7.85534 12.9997 7.69573 12.8223 7.32143C12.6449 6.94713 12.8045 6.49989 13.1788 6.32248L15.0657 5.42814C14.1349 4.99313 13.0963 4.7502 12 4.7502C7.99593 4.7502 4.75 7.99614 4.75 12.0002C4.75 16.0043 7.99593 19.2502 12 19.2502C16.0041 19.2502 19.25 16.0043 19.25 12.0002C19.25 11.586 19.5858 11.2502 20 11.2502C20.4142 11.2502 20.75 11.586 20.75 12.0002C20.75 16.8327 16.8325 20.7502 12 20.7502C7.16751 20.7502 3.25 16.8327 3.25 12.0002C3.25 7.16771 7.16751 3.2502 12 3.2502C13.1846 3.2502 14.315 3.48593 15.3461 3.91301L14.7884 2.23701C14.6576 1.84399 14.8702 1.41936 15.2632 1.28857Z"/>
    </svg>
</template>

<script>
export default {
    name: 'IconRotate',

    props: {
        className: String
    }
};
</script>
