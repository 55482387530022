<template>
    <div v-if="breadcrumbs.length"
         class="visual-breadcrumbs">
        <div class="visual-breadcrumbs__container">
            <div class="visual-breadcrumbs__row">
                <router-link v-if="breadcrumbs.length > 1"
                             :to="{path: breadcrumbs[breadcrumbs.length - 2].link}"
                             class="visual-breadcrumbs__back">
                    <svg width="56" height="56" viewBox="0 0 56 56" class="visual-breadcrumbs__back-circle">
                        <circle cx="28" cy="28" r="27"></circle>
                    </svg>
                    <svg width="17" height="12" class="visual-breadcrumbs__back-arrow">
                        <use xlink:href="/webicons/sprite.svg#i-slider-arr-left"></use>
                    </svg>
                </router-link>
                <ul class="visual-breadcrumbs__list">
                    <li v-for="(item, index) in breadcrumbs"
                        :key="index"
                        class="visual-breadcrumbs__item">
                        <router-link :to="{path: item.link}"
                                     :class="['visual-breadcrumbs__link', {'is-disabled': item.disabled}]">
                            <span class="visual-breadcrumbs__text"
                                  v-html="item.name" />
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Breadcrumbs',

    computed: {
        ...mapGetters({
            breadcrumbs: 'GET_BREADCRUMBS'
        })
    }
};
</script>

<style lang="scss">
    @import "Breadcrumbs.scss";
</style>
