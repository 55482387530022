/**
 * @version 1.4
 * @author Kelnik Studios {http://kelnik.ru}
 * @link https://kelnik.gitbooks.io/kelnik-documentation/content/front-end/components/utils.html documentation
 */
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import $ from 'jquery';
import {DESKTOP_BREAKPOINT} from './constants';

class Utils {
    /**
     * Метод устанавливает комфорную задержку выполнения анимации.
     * @return {Number} comfortableAnimationTimeValue - значение в мс.
     */
    static comfortableAnimationTime() {
        const comfortableAnimationTimeValue = 300;

        return comfortableAnimationTimeValue;
    }

    /**
     * Метод полностью очищает весь html элемент.
     * @param {Object} element - DOM-элемент, который необходимо очистить.
     */
    static clearHtml(element) {
        element.innerHTML = '';
    }

    /**
     * Метод вставляет содержимое в блок.
     * @param {Object} element - элемент в который нужно вставить.
     * @param {Object/string} content - вставляемый контент.
     */
    static insetContent(element, content) {
        if (typeof content === 'string') {
            element.insertAdjacentHTML('beforeend', content);
        } else if (typeof content === 'object') {
            element.appendChild(content);
        }
    }

    /**
     * Метод полностью удаляет элемент из DOM-дерева.
     * @param {Object} element - элемент, который необходимо удалить.
     */
    static removeElement(element) {
        if (!element || !element.parentNode) {
            return;
        }
        // node.remove() не работает в IE11
        element.parentNode.removeChild(element);
    }


    /**
     * Метод показывает элемент.
     * @param {Node} element - элемент, который необходимо показать.
     */
    static show(element) {
        element.style.display = 'block';
    }

    /**
     * Метод скрывает элемент.
     * @param {Node} element - элемент, который необходимо скрыть.
     */
    static hide(element) {
        element.style.display = 'none';
    }

    /**
     * Метод очищает инлайновые стили.
     * @param {Node} element - элемент, который необходимо скрыть.
     */
    static clearInline(element) {
        element.removeAttribute('style');
    }

    /**
     * Метод отправляет ajax запрос на сервер.
     * @param {Object} data - отправляемые данные.
     * @param {String} url - маршрут по которому нужно произвести запрос.
     * @param {Object} callback -  функции обратного вызова, при успехе вызовет поле success, а при ошибке - error.
     * @param {String} method - метод отправки запроса
     */
    static send(data, url, callback = {}, method = 'POST') {
        const xhr = new XMLHttpRequest();
        const statusSuccess = 200;

        xhr.open(method, url);

        if (!(data instanceof FormData)) {
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        }

        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        xhr.send(data);

        xhr.onload = function XHR() {
            if (xhr.status === statusSuccess) {
                const req = JSON.parse(this.responseText);

                if (callback.success) {
                    callback.success(req);
                }
            } else if (callback.error) {
                callback.error(xhr.status);
            }

            if (callback.complete) {
                callback.complete();
            }
        };

        xhr.onerror = function XHR() {
            if (callback.error) {
                callback.error(xhr.status);
            }

            if (callback.complete) {
                callback.complete();
            }
        };
    }

    /**
     * Метод проверяет наличие интернета
     * @return {boolean} - При наличии результатом будет true, а при отсутсвии false.
     */
    static checkInternetConnection() {
        return navigator.onLine;
    }

    /**
     * Метод проверяет присутствует ли ключ в объекте
     * @param {Object} object - проверяем объект
     * @param {String} key - ключ, наличие которого проверяет в объекте
     * @return {boolean} - присутствует или нет ключ в объекте
     */
    static keyExist(object, key) {
        return Object.prototype.hasOwnProperty.call(object, key);
    }

    /**
     * Метод проверяет пустой объект или нет
     * @param {Object} object - объект проверяемый на пустоту
     * @return {boolean} - true если пустой и false если полный
     */
    static isEmptyObject(object) {
        const empty = 0;

        return Object.keys(object).length === empty;
    }

    /**
     * Проверяет переданные данные на строку
     * @param {String} string - данные на проверку
     * @return {boolean} - возращает true, если строка, и false наоборот
     */
    static isString(string) {
        return typeof string === 'string';
    }

    /**
     * Узнает index элемента в родительской элемент
     * Аналог jquery.index()
     * @param {Node} element - искомый элемент
     * @return {number} - порядковый номер (индекс) в родительском элементе
     */
    static getElementIndex(element) {
        return Array.from(element.parentNode.children).indexOf(element);
    }

    /**
     * Проверяет, поддерживает ли устройство touch-события
     * @return {boolean} - возвращает true, если Touch-устройство, и false наоборот
     */
    static isTouch() {
        return Boolean(typeof window !== 'undefined' &&
            ('ontouchstart' in window ||
                (window.DocumentTouch &&
                    typeof document !== 'undefined' &&
                    document instanceof window.DocumentTouch))) ||
            Boolean(typeof navigator !== 'undefined' && (navigator.maxTouchPoints || navigator.msMaxTouchPoints));
    }

    /**
     * Узнает находится ли элемент во вьюпорте
     * @param {Node} element - искомый элемент
     * @param {Number} offset - дополнительный отступ
     * @return {boolean} - возращает true, если элемент виден на экране, и false наоборот
     */
    static isInViewport(element, offset = 0) {
        const rect = element.getBoundingClientRect();
        const top = rect.top + offset;
        const left = rect.left + offset;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth;
        const belowViewport = 0;

        const verticalInView = (top <= windowHeight) && ((top + rect.height) >= belowViewport);
        const horizontalInView = (left <= windowWidth) && ((left + rect.width) >= belowViewport);

        return verticalInView && horizontalInView;
    }

    /**
     * Определяет высоту экрана
     * @returns {Number} Значение высоты экрана
     */
    static getWindowHeight() {
        return window.innerHeight || document.documentElement.clientHeight;
    }

    /**
     * Определяет ширину экрана
     * @returns {Number} Значение ширины экрана
     */
    static getWindowWidth() {
        return window.innerWidth || document.documentElement.clientWidth;
    }

    /**
     * Определяет положение скролла
     * @returns {Number} Значение положения скролла
     */
    static getScrollTop() {
        return window.pageYOffset || document.documentElement.scrollTop;
    }

    /**
     * Фиксирует страницу для запрета прокрутки
     * @param {Node} element - кроме данного элемента у всех остальных сбросится скролл
     */
    static bodyFixed(element) {
        disableBodyScroll(element, {reserveScrollBarGap: true});

        // Получаем ширину скроллбара из результата работы плагина
        setTimeout(() => {
            const padding = getComputedStyle(document.body).paddingRight;
            const header = document.querySelector('.j-header__base');
            const menu = document.querySelector('.j-menu');

            if (header) {
                header.style.right = padding;
            }

            if (menu) {
                menu.style.paddingRight = padding;
            }
        });
    }

    /**
     * Снимаем фиксирование страницы
     */
    static bodyStatic() {
        clearAllBodyScrollLocks();

        setTimeout(() => {
            const header = document.querySelector('.j-header__base');
            const menu = document.querySelector('.j-menu');

            if (header) {
                header.style.right = 0;
            }

            if (menu) {
                menu.style.paddingRight = 0;
            }
        });
    }

    /**
     * Метод конвертирует строку (можно с разрядами) в число
     * @param {string} str - необходимая строка.
     * @return {number} - сковертированное число.
     */
    static convertToNumber(str) {
        return parseFloat(str
            .toString()
            .replace(/\s/g, ''));
    }

    /**
     * Убирает все пробелы из строки
     * @param {string} str - необходимая строка.
     * @return {string} - преобразованная строка.
     */
    static replaceSpace(str) {
        return str.replace(/\s/g, '');
    }

    /**
     * Конвертирует число или строку в строку с рарядами.
     * @param {string/number} value - число или строка.
     * @return {string} - преобразованная строка.
     */
    static convertToDigit(value) {
        return Number(value).toLocaleString('ru-Ru');
    }

    /**
     * Конвертирует целое число в дробное
     * @param {string/number} value - число или строка.
     * @param {number} denominator - целый делитель.
     * @return {number} - преобразованная строка.
     */
    static convertToRank(value, denominator) {
        const result = Number(value);

        return result / denominator;
    }

    /**
     * Генерация псевдослучайных чисел в заданном  числовом интервале
     * @param {Number} min - минимальное псевдослучайное число
     * @param {Number} max - максимальное псевдослучайное число
     * @return {Number} - возвращает псевдослучайное число в интервале [min, max]
     */
    static random(min, max) {
        return Math.floor((Math.random() * (max - min)) + min);
    }

    /**
     * Конвертирует строку в camel-сase
     * @param {string} string - необходимая строка
     * @return {string} - результат
     */
    static toCamelCase(string) {
        // eslint-disable-next-line no-useless-escape
        const dashRegEx = /\-([a-z])/ig;

        return string.replace(dashRegEx, (m, letter) => {
            return letter.toUpperCase();
        });
    }

    /**
     * Возвращает дата-атрибуты из всего массива атрибутов элемента
     * Стандартный dataset не работает для svg элементов в IE.
     * @param {object} element - дом элемент
     * @return {return} - объект с дата-атрибутами
     */

    static getDataSet(element) {
        const attributes = Array.from(element.attributes);
        // eslint-disable-next-line no-useless-escape
        const dataRegEx = /^data\-(.+)/;
        const dataset = {};
        const first = 1;

        attributes.forEach((item) => {
            const match = item.name.match(dataRegEx);

            if (match) {
                dataset[this.toCamelCase(match[first])] = item.value;
            }
        });

        return dataset;
    }

    /**
     * Выбирает окончание слова.
     * @param {number} n - количество
     * @param {array} words - масcив слов. Например показать еще ['квартиру', 'квартиры', 'квартир']
     * @return {string} - слово в правильном склонении.
     */
    static pluralWord(n, words) {
        /* eslint-disable  */
        const $i = n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2;
        /* eslint-enable*/

        return words[$i];
    }

    /**
     * Устанавиливает гет-параметры
     * @param {array} params - массив объектов (ключ/значение) для установки в гет
     */
    static setUrlParams(params) {
        const query = new URLSearchParams();

        params.forEach((item) => {
            query.append(item.param, item.value);
        });

        window.history.pushState(null, null, `?${query.toString()}`);
    }

    /**
     * Получает значение гет-параметра
     * @param {string} param - ключ для поиска в гет
     * @return {string} value - значение гет
     */
    static getUrlParams(param) {
        const get = window.location.search;
        const url = new URLSearchParams(get);

        return url.get(param);
    }

    /**
     * Получает все гет-параметры из url-а
     * @return {object} - объект параметров
     */
    static getAllUrlParams() {
        const urlSearch = decodeURIComponent(window.location.search);
        const getParams = urlSearch.match(new RegExp('[^&?]+', 'gm'));
        const result = {};

        if (getParams) {
            getParams.forEach((param) => {
                const splitParam = param.split('=');

                if (splitParam[0] && splitParam[1]) {
                    result[splitParam[0]] = splitParam[1];
                }
            });
        }

        return result;
    }

    /**
     * Возвращает булевое значение если текущий размер находится в интервале переданных брейкопинтов.
     * Например при 380 и переданных значениях (320 480) вернет true, во всех остальных случаях false.
     * @param {Number} min - минимальное значение ширины. Будет тру если больше или равно.
     * @param {Number} max - максимальное значение ширины. Будет тру если меньше.
     * @return {boolean} булевое значение если попадает в переданный интервал
     */
    static isBreakpoint(min, max) {
        return window.innerWidth >= min && window.innerWidth < max;
    }

    /**
     * Проверяет, мобилка или нет
     * @returns {Boolean} - true - мобилка
     */
    static isMobile() {
        return Utils.getWindowWidth() < DESKTOP_BREAKPOINT;
    }

    /**
     * Проверяет является ли текущий браузер IE
     * @return {boolean} true - является
     */
    static isIE() {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE');

        // eslint-disable-next-line
        return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    }

    /**
     * Троттлинг функции означает, что функция вызывается не более одного раза в указанный период времени (например, раз в 10 секунд).
     * @param {function} fun - Функция выполнения
     * @param {number} time - Миллисекунды
     * @return {function} - замыкание
     */
    static throttle(fun, time) {
        let lastCall = null;

        return (args) => {
            const previousCall = lastCall;

            lastCall = Date.now();

            // eslint-disable-next-line no-undefined
            if (previousCall === undefined || (lastCall - previousCall) > time) {
                fun(args);
            }
        };
    }

    /**
     * Debouncing функции означает, что все вызовы будут игнорироваться до тех пор,
     * пока они не прекратятся на определённый период времени. Только после этого функция будет вызвана.
     * @param {function} fun - Функция выполнения
     * @param {number} time - Миллисекунды
     * @return {function} - Замыкание
     */
    static debounce(fun, time) {
        let lastCall = null;

        return (args) => {
            const previousCall = lastCall;

            lastCall = Date.now();

            if (previousCall && ((lastCall - previousCall) <= time)) {
                clearTimeout(this.lastCallTimer);
            }

            this.lastCallTimer = setTimeout(() => {
                fun(args);
            }, time);
        };
    }

    /**
     * Клик по элементу или мимо
     *
     * @param {array} selectors - Строка или массив селектор(а)ов отслеживаемых элемент(а)ов
     * @param {function} outside - Callback если клик вне элемент(а)ов
     * @param {function} inside - Callback если клик по элемент(у)ам
     * @param {boolean} updateElements - Нужно ли каждый раз обновлять элементы при клике
     */
    static clickOutside(selectors, outside = function() {}, inside = function() {}, updateElements = false) {
        if (!selectors) {
            return;
        }

        let elements = [];

        if (selectors.nodeType === Node.ELEMENT_NODE) {
            elements = [[selectors]];
        } else if (typeof selectors == 'string') {
            elements.push(Array.from(document.querySelectorAll(selectors)));
        } else {
            Array.from(selectors).forEach((selector) => {
                elements.push(Array.from(document.querySelectorAll(selector)));
            });
        }

        document.addEventListener('click', (event) => {
            let target = event.target;

            if (updateElements) {
                elements = [];

                if (selectors.nodeType === Node.ELEMENT_NODE) {
                    elements = [[selectors]];
                } else if (typeof selectors == 'string') {
                    elements.push(Array.from(document.querySelectorAll(selectors)));
                } else {
                    Array.from(selectors).forEach((selector) => {
                        elements.push(Array.from(document.querySelectorAll(selector)));
                    });
                }
            }

            do {
                try {
                    // eslint-disable-next-line no-loop-func
                    elements.forEach((element) => {
                        if (element.indexOf(target) !== -1) {
                            inside();

                            // eslint-disable-next-line no-throw-literal
                            throw false;
                        }
                    });
                } catch (error) {
                    return;
                }

                target = target.parentNode;
            } while (target);

            outside();
        });
    }

    /**
     * Проверяет является ли сущность дом элементом
     * @param {*} elem - элемент для проверки
     * @returns {boolean} true - является
     */
    static isElement(elem) {
        return Boolean(elem.tagName);
    }

    static closest(element, parent) {
        if (!element || !parent) {
            return false;
        }

        return $(element).closest(parent)[0];
    }

    /**
     * кодирование строки Unicode в base-64
     * @param {String} str - Строка
     * @return {String} Закодированная строка в base64
     */
    static toBase64(str) {
        return window.btoa(unescape(encodeURIComponent(str)));
    }

    /**
     * декодирование строки из base-64 в Unicode
     * @param {String} str - закодированная строка в base64
     * @returns {String} Декодированная строка
     */
    static fromBase64(str) {
        return decodeURIComponent(escape(window.atob(str)));
    }

    static copyToClipboard(string) {
        const copytext = document.createElement('input');

        copytext.value = string;
        document.body.appendChild(copytext);
        copytext.select();
        document.execCommand('copy');
        document.body.removeChild(copytext);
    }

    /**
     * Расставляет мягкий перенос в строке
     * @param {String} string - текст, в котором надо расставить переносы
     * @return {String} - текст с переносами
     */
    static hyphenate(string) {
        let text = string;
        const all = '[а-яА-Я]';
        const glas = '[аеёиоуыэюяАЕЁИОУЫЭЮЯ]';
        const sogl = '[бвгджзклмнпрстфхцчшщБВГДЖЗКЛМНПРСТФХЦШЩ]';
        const zn = '[йъьЙЪЬ]';
        const shy = '\xAD';
        const re = [];

        re[1] = new RegExp(`(${zn})(${all}${all})`, 'ig');
        re[2] = new RegExp(`(${glas})(${glas}${all})`, 'ig');
        re[4] = new RegExp(`(${glas}${sogl})(${sogl}${glas})`, 'ig');
        re[3] = new RegExp(`(${sogl}${glas})(${sogl}${glas})`, 'ig');
        re[5] = new RegExp(`(${glas}${sogl})(${sogl}${sogl}${glas})`, 'ig');
        re[6] = new RegExp(`(${glas}${sogl}${sogl})(${sogl}${sogl}${glas})`, 'ig');

        for (let j = 0; j < 2; ++j) {
            for (let i = 0; i <= 6; ++i) {
                text = text.replace(re[i], `$1${shy}$2`);
            }
        }

        return text;
    }

    static loadHandler(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();

            img.src = url;
            img.onload = (event) => {
                const src = event.currentTarget.getAttribute('src');
                const isSvg = src.indexOf('.svg');

                if (isSvg === -1) {
                    resolve({
                        width : event.currentTarget.width,
                        height: event.currentTarget.height
                    });
                } else {
                    $.get(src, (svgxml) => {
                        const viewBox = svgxml.documentElement.attributes.viewBox.value.split(' ');

                        resolve({
                            width : Number(viewBox[2]),
                            height: Number(viewBox[3])
                        });
                    }, 'xml');
                }
            };
            img.onerror = (error) => {
                reject(error);
            };
        });
    }

    /**
     * Установка cookie
     * @param {string} name - имя cookie
     * @param {any} value - значение cookie
     * @param {object} options - объект опций
     */
    static setCookie(name, value, options = {}) {
        // eslint-disable-next-line no-param-reassign
        options = {
            path: '/',
            ...options
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

        for (const optionKey in options) {
            if (Utils.keyExist(options, optionKey)) {
                updatedCookie = `${updatedCookie}; ${optionKey}`;

                const optionValue = options[optionKey];

                if (optionValue !== true) {
                    updatedCookie = `${updatedCookie}=${optionValue}`;
                }
            }
        }
        document.cookie = updatedCookie;
    }

    static getCookie(name) {
        const matches = document.cookie
            .match(new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`));

        return matches ?
            decodeURIComponent(matches[1]) :
            // eslint-disable-next-line no-undefined
            undefined;
    }

    static deleteCookie(name) {
        Utils.setCookie(name, '', {
            'max-age': -1
        });
    }

    /**
     * Возвращает пустая ли переменная
     * @param {any} variable - переменная для проверки
     * @return {boolean} - пустая ли переменная?
     */
    static isSet(variable) {
        return !(typeof variable === 'undefined' || variable === null);
    }
}

class Counter {
    constructor(stopValue = 3) {
        this.stopValue = stopValue;
        this.countValue = this.stopValue;
    }

    tick() {
        if (!this.countValue) {
            this.countValue = this.stopValue;

            return true;
        }

        this.countValue--;

        return false;
    }
}

class InternetConnection {
    constructor(callback = () => {
        return null;
    }, interval = 200) {
        this.counter = new Counter(3);

        this.callback = callback;
        this.interval = interval;

        this._bindMethodsContext();
    }

    connect() {
        if (!Utils.checkInternetConnection() && !this.counter.tick()) {
            this._reconnect(this.connect);

            return;
        }

        this.callback();
    }

    _bindMethodsContext() {
        this.connect = this.connect.bind(this);
    }

    /**
     * @Public
     * Возвращает контент с ошибками сети
     * @return {Object} - объект со списком контента для обработки ошибок
     */
    getErrorMessage() {
        return Utils.checkInternetConnection() ?
            '<h1>На сервере произошла ошибка. Повторите запрос позднее</h1>' :
            '<h1>Вы не подключены к интернету. Повторите запрос позднее</h1>';
    }

    _reconnect(callback) {
        this.setInterval = setTimeout(() => {
            callback();
        }, this.interval);
    }
}

export {Utils, Counter, InternetConnection};
