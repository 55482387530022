<template>
    <svg :class="className" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5304 7.46967C14.8233 7.76256 14.8233 8.23744 14.5304 8.53033L11.0608 12L14.5304 15.4697C14.8233 15.7626 14.8233 16.2374 14.5304 16.5303C14.2375 16.8232 13.7627 16.8232 13.4698 16.5303L8.93945 12L13.4698 7.46967C13.7627 7.17678 14.2375 7.17678 14.5304 7.46967Z"/>
    </svg>
</template>

<script>
export default {
    name: 'IconArrowLeft',

    props: {
        className: String
    }
};
</script>
