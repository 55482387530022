import axios from 'axios';
import loadHandler from './helpers/loadHandler';
import {Utils} from 'common/scripts/utils';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const url = '/tests/visual.php';

export default new Vuex.Store({
    /**
     * Глобальное хранилище для всего приложения
     * Доступ только через геттеры, изменение через мутацию.
     */
    state: {
        data: {
            render     : '',
            elements   : '',
            breadcrumbs: ''
        },
        compass: {
            degree: 0
        },
        canvasSize: {
            width : 0,
            height: 0
        },
        canvasShift   : 0,
        perspectives  : null,
        rotate        : null,
        filters       : {},
        pointers      : [],
        renderPointers: [],
        dataStep      : {},
        form          : '',
        formData      : '',
        isProc        : false,
        isReady       : false,
        isMobileClue  : true,
        mode          : null,
        link          : null
    },

    /**
     * Методы для получения данных из стейта.
     */
    getters: {
        GET_MODE(state) {
            return state.mode;
        },

        GET_LINK(state) {
            return state.link;
        },

        GET_RENDER(state) {
            return state.data.render;
        },

        GET_ELEMENTS(state) {
            return state.data.elements;
        },

        GET_CANVAS_SIZE(state) {
            return state.canvasSize;
        },

        GET_CANVAS_SHIFT(state) {
            return state.canvasShift;
        },

        GET_PERSPECTIVES(state) {
            return state.perspectives;
        },

        GET_FILTERS(state) {
            return state.filters;
        },

        GET_POINTERS(state) {
            return state.pointers;
        },

        GET_RENDER_POINTERS(state) {
            return state.renderPointers;
        },

        GET_BREADCRUMBS(state) {
            return state.data.breadcrumbs;
        },

        GET_METADATA(state) {
            return state.data.meta;
        },

        GET_DATA_STEP(state) {
            return state.dataStep;
        },

        GET_COMPASS(state) {
            return state.compass;
        },

        GET_FORM_DATA(state) {
            return state.formData;
        },

        GET_FORM(state) {
            return state.form;
        },

        GET_ROTATE(state) {
            return state.rotate;
        },

        GET_IS_PROCESSING(state) {
            return state.isProc;
        },

        GET_IS_READY(state) {
            return state.isReady;
        },

        GET_IS_MOBILE_CLUE(state) {
            return state.isMobileClue;
        },

        GET_IS_MINI(state) {
            return state.data.isMini;
        }
    },

    /**
     * События, которые введут за собой изменение стейта.
     * https://vuex.vuejs.org/ru/guide/actions.html
     */
    actions: {
        send(context, {body, query = {}}) {
            context.commit('SET_IS_PROCESSING', true);
            context.commit('SET_DATA_STEP', body);
            context.commit('SET_FORM_DATA', {
                type: 'init',
                query
            });

            return new Promise((resolve) => {
                axios.post(Vue.prototype.$dataset.url || url, this.getters.GET_FORM_DATA)
                    .then((response) => {
                        const status = response.data.success;
                        const errors = response.data.messages;

                        if (!status) {
                            throw new Error(errors.length ? errors : 'Ошибка на сервере');
                        }

                        if (errors && errors.length) {
                            errors.forEach((error) => {
                                console.error(error);
                            });
                        }

                        const data = response.data.data;
                        const render = data.render.link;
                        const renderSize = data.render.size;
                        const isRenderSize = Number(renderSize.width) && Number(renderSize.height);

                        if (isRenderSize) {
                            context.dispatch('setInfo', {
                                data,
                                size: renderSize
                            }).then(() => {
                                resolve();
                            });
                        } else {
                            loadHandler(render)
                                .then((size) => {
                                    context.dispatch('setInfo', {
                                        data,
                                        size
                                    }).then(() => {
                                        resolve();
                                    });
                                });
                        }
                    })
                    .catch((error) => {
                        console.error(`При получении данных произошла ошибка ${error}`);
                        throw new Error();
                    });
            });
        },

        setInfo(context, {data, size}) {
            context.commit('SET_MODE');
            context.commit('SET_LINK');
            context.commit('SET_CANVAS_SIZE', size);
            context.commit('SET_DATA', data);
            context.commit('SET_CANVAS_SHIFT', data.render.startPercentage);
            context.commit('SET_PERSPECTIVES', data);
            context.commit('SET_ROTATE', data);
            context.commit('SET_POINTERS', data);
            context.commit('SET_FILTERS', data);
            context.commit('SET_RENDER_POINTERS', data);
            context.commit('SET_COMPASS', data.compass);
            context.commit('SET_IS_PROCESSING', false);
            context.commit('SET_IS_READY');
        },


        changeFilter(context, {form, query = {}}) {
            context.commit('SET_FORM', form);
            context.commit('SET_FORM_DATA', {
                type: 'filter',
                query
            });
            context.commit('SET_IS_PROCESSING', true);

            axios.post(Vue.prototype.$dataset.url || url, context.getters.GET_FORM_DATA)
                .then((response) => {
                    const data = response.data.data;

                    context.commit('SET_ELEMENTS', data);
                    context.commit('SET_POINTERS', data);
                    context.commit('SET_FILTERS', data);
                    context.commit('SET_IS_PROCESSING', false);
                })
                .catch((error) => {
                    console.error(`При фильтрации произошла ошибка= ${error}`);
                });
        },

        changeStateLoading(context, state) {
            context.commit('SET_IS_PROCESSING', state);
        },

        changeMobileClue({commit}, data) {
            commit('SET_IS_MOBILE_CLUE', data);
        },

        changeCanvasShift(context, data) {
            context.commit('SET_CANVAS_SHIFT', data);
        }
    },

    /**
     * Методы для изменения стейта.
     */
    mutations: {
        SET_MODE(state) {
            state.mode = Vue.prototype.$dataset.propertyMode;
        },

        SET_LINK(state) {
            state.link = Vue.prototype.$dataset.propertyLink;
        },

        SET_CANVAS_SIZE(state, data) {
            state.canvasSize = data;
        },

        SET_DATA(state, data) {
            state.data = data;
        },

        SET_CANVAS_SHIFT(state, shift) {
            const numData = Number(shift);

            state.canvasShift = numData >= 0 && numData <= 1 ? numData : 0;
        },

        SET_PERSPECTIVES(state, data) {
            state.perspectives = data?.perspective || null;
        },

        SET_ELEMENTS(state, data) {
            state.data.elements = data.elements;
        },

        SET_POINTERS(state) {
            const elements = state.data.elements;
            const pointers = [];

            if (elements.length) {
                elements.forEach((element) => {
                    if (element.pointer) {
                        element.pointer.id = element.id;
                        element.pointer.link = element.link;
                        element.pointer.disabled = element.disabled;
                        pointers.push(element.pointer);
                    }
                });
            }

            state.pointers = pointers;
        },

        SET_RENDER_POINTERS(state, data) {
            state.renderPointers = data.pointers && data.pointers.length ? data.pointers : [];
        },

        SET_FILTERS(state, data) {
            state.filters = data.filters || {};
        },

        SET_DATA_STEP(state, data) {
            state.dataStep = data;
        },

        SET_COMPASS(state, data) {
            state.compass = data;
        },

        SET_FORM(state, form) {
            state.form = form;
        },

        SET_FORM_DATA(state, {type, query = {}}) {
            const form = this.getters.GET_FORM;
            const dataStep = this.getters.GET_DATA_STEP;

            state.formData = form ? new FormData(form) : new FormData();

            for (const item in dataStep) {
                if (Utils.keyExist(dataStep, item)) {
                    if (!state.formData.has(item)) {
                        state.formData.append(item, dataStep[item]);
                    }
                }
            }

            /**
             * query - данные из строки поиска (get-параметры)
             */
            for (const item in query) {
                if (Utils.keyExist(query, item)) {
                    if (!state.formData.has(item)) {
                        state.formData.append(item, query[item]);
                    }
                }
            }

            state.formData.append('type', type);
        },

        SET_ROTATE(state, data) {
            state.rotate = data?.perspective.find((item) => {
                return item.active;
            }).id || null;
        },

        SET_IS_PROCESSING(state, value) {
            state.isProc = value;
        },

        SET_IS_READY(state) {
            state.isReady = true;
        },

        SET_IS_MOBILE_CLUE(state, value) {
            state.isMobileClue = value;
        }
    }
});
