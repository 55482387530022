<template>
    <div :class="['visual-filters', {'is-open': isOpen}]">
        <!-- Комнаты -->
        <div v-if="filters.room"
             class="visual-filters__item">
            <transition name="fade">
                <form v-if="isOpen"
                      ref="form"
                      @change="change"
                      class="visual-filters__form">
                    <div class="visual-filters__form-wrapper">
                        <div class="visual-filters__form-title">Спален</div>
                        <ul class="visual-filters__list">
                            <li v-for="item in filters.room"
                                :key="item.id"
                                class="visual-filters__list-item">
                                <ButtonCheckbox :name="`room[${item.id}]`"
                                                :checked="item.active"
                                                :disabled="item.disabled"
                                                :value="item.id"
                                                :title="item.title"
                                                @change="changeCheckbox(item)"/>
                            </li>
                        </ul>
                    </div>
                    <div v-if="filters.floor"
                         class="visual-filters__form-wrapper visual-filters__form-wrapper_for_floors">
                        <Sly :is-mobile="isMobile"
                             @closeFilter="closeFilter"/>
                    </div>
                </form>
            </transition>
            <button class="visual-filters__open-button"
                    @click="toggleFilter">
                <transition name="fade">
                    <IconFilter v-if="!isOpen"
                                class-name="visual-filters__icon-filter"/>
                    <IconClose v-else
                               class-name="visual-filters__icon-filter"/>
                </transition>
            </button>
        </div>
        <!-- Входа -->
        <div v-if="filters.inputs"
             class="visual-filters__item">
            <transition name="fade">
                <form v-if="isOpen"
                      ref="form"
                      @change="change"
                      class="visual-filters__form">
                    <div class="visual-filters__form-wrapper">
                        <div class="visual-filters__form-title">Количество входов</div>
                        <ul class="visual-filters__list">
                            <li v-for="item in filters.inputs"
                                :key="item.id"
                                class="visual-filters__list-item">
                                <ButtonCheckbox :name="`input[${item.id}]`"
                                                :checked="item.active"
                                                :disabled="item.disabled"
                                                :value="item.id"
                                                :title="item.title"
                                                @change="changeCheckbox(item)"/>
                            </li>
                        </ul>
                    </div>
                    <div v-if="filters.floor"
                         class="visual-filters__form-wrapper visual-filters__form-wrapper_for_floors">
                        <Sly :is-mobile="isMobile"
                             @closeFilter="closeFilter"/>
                    </div>
                </form>
            </transition>
            <button class="visual-filters__open-button"
                    @click="toggleFilter">
                <transition name="fade">
                    <IconFilter v-if="!isOpen"
                                class-name="visual-filters__icon-filter"/>
                    <IconClose v-else
                               class-name="visual-filters__icon-filter"/>
                </transition>
            </button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ButtonCheckbox from '../ButtonCheckbox/ButtonCheckbox';
import IconClose from '../Icons/icon-close';
import IconFilter from '../Icons/icon-filter';
import Observer from 'common/scripts/observer';
import Sly from '../Sly/Sly';

const observer = new Observer();

export default {
    name: 'Filters',

    components: {
        ButtonCheckbox,
        IconClose,
        IconFilter,
        Sly
    },

    props: {
        isMobile: Boolean
    },

    data() {
        return {
            isOpen: false
        };
    },

    computed: {
        ...mapGetters({
            dataStep    : 'GET_DATA_STEP',
            filters     : 'GET_FILTERS',
            isMobileClue: 'GET_IS_MOBILE_CLUE'
        })
    },

    created() {
        this._subscribes();
    },

    mounted() {
        this._bindEvents();
    },

    beforeDestroy() {
        observer.unsubscribe('closePanels');
    },

    methods: {
        ...mapActions(['changeFilter', 'send', 'changeMobileClue']),

        _subscribes() {
            observer.subscribe('closePanels', () => {
                this.isOpen = false;
            });
        },

        _bindEvents() {
            const events = ['resize', 'orientationchange'];

            events.forEach((event) => {
                window.addEventListener(event, this._onResize.bind(this));
            });
        },

        _onResize() {
            if (this.isOpen) {
                this.isOpen = false;
            }
        },

        closeFilter() {
            this.isOpen = false;
        },

        toggleFilter() {
            this.isOpen = !this.isOpen;

            if (this.isMobile && this.isMobileClue) {
                this.changeMobileClue(false);
            }
        },

        change() {
            this._setURLParams();
            this.changeFilter({
                form : this.$refs.form,
                query: this.$route.query
            });
            this.isOpen = false;
        },

        _setURLParams() {
            const formData = new FormData(this.$refs.form);
            const filtersData = {};
            const rotate = this.$route.query.rotateId ? {rotateId: this.$route.query.rotateId} : {};

            formData.forEach((value, key) => {
                filtersData[key] = value;
            });

            const query = {
                ...filtersData,
                ...rotate
            };

            this.$router.replace({query});
        },

        changeCheckbox(item) {
            item.checked = !item.checked;
        }
    }
};
</script>

<style lang="scss">
    @import "Filters.scss";
</style>
