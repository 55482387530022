<template>
    <div :class="['visual-wrapper', {'is-show': isReady}]">
        <Canvas ref="canvas"
                :is-mobile="isMobile"
                :is-touch="isTouch"
                :is-floor-stage="true"
                modify="visual-canvas_page_floor"/>
        <Compass/>
        <Sly v-if="!isMini" :is-mobile="isMobile"/>
        <Rotate :is-mobile="isMobile"/>
        <Filters v-if="!isMini" :is-mobile="isMobile"/>
        <Panel :is-mobile="isMobile"/>
        <transition name="fade">
            <TouchClue v-if="isMobileClue"/>
        </transition>
        <BookingPopup />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import BookingPopup from '../../components/BookingPopup/BookingPopup';
import Canvas from '../../components/Canvas/Canvas';
import Compass from '../../components/Compass/Compass';
import Filters from '../../components/Filters/Filters';
import Panel from '../../components/Panel/Panel';
import Rotate from '../../components/Rotate/Rotate';
import Sly from '../../components/Sly/Sly';
import store from '../../store';
import TouchClue from '../../components/TouchClue/TouchClue';

export default {
    components: {
        BookingPopup,
        Canvas,
        Compass,
        Filters,
        Panel,
        Rotate,
        Sly,
        TouchClue
    },

    props: {
        isMobile: Boolean,
        isTouch : Boolean
    },

    computed: {
        ...mapGetters({
            isMobileClue: 'GET_IS_MOBILE_CLUE',
            isReady     : 'GET_IS_READY',
            isMini      : 'GET_IS_MINI'
        })
    },

    beforeRouteEnter(to, from, next) {
        /**
         * Отправляет данные на сервер.
         */
        const body = {
            step: to.meta.step,
            ...to.params.buildingId && {
                buildingId: to.params.buildingId
            },
            ...to.params.sectionId && {
                sectionId: to.params.sectionId
            },
            ...to.params.floorId && {
                floorId: to.params.floorId
            },
            ...to.params
        };

        store.dispatch('send', {
            body,
            query: to.query
        }).then(() => {
            next();
        });
    },

    mounted() {
        this._scrollToCanvas();
    },

    methods: {
        ...mapActions(['send']),

        _scrollToCanvas() {
            if (this.$refs.canvas.$el.getBoundingClientRect().top < 0) {
                this.$refs.canvas.$el.scrollIntoView({
                    block   : 'start',
                    behavior: 'smooth'
                });
            }
        }
    }
};
</script>
