import App from './App.vue';
import router from './router';
import store from './store';
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

const mountElement = document.querySelector('#visual');

Vue.prototype.$dataset = {...mountElement.dataset};

router.beforeEach((to, from, next) => {
    for (const param in Vue.prototype.$dataset) {
        if (!Object.prototype.hasOwnProperty.call(to.params, param)) {
            to.params[param] = Vue.prototype.$dataset[param];
        }
    }
    next();
});

new Vue({
    router,
    store,
    render: (h) => {
        return h(App);
    }
}).$mount(mountElement);
