export default (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = url;
        img.onload = (event) => {
            const src = event.currentTarget.getAttribute('src');
            const isSvg = src.indexOf('.svg');

            if (isSvg === -1) {
                resolve({
                    width : event.currentTarget.width,
                    height: event.currentTarget.height
                });
            } else {
                resolve({
                    width : 1920,
                    height: 1080
                });
                $.get(src, (svgxml) => {
                    const viewBox = svgxml.documentElement.attributes.viewBox.value.split(' ');

                    resolve({
                        width : Number(viewBox[2]),
                        height: Number(viewBox[3])
                    });
                }, 'xml');
            }
        };
        img.onerror = (error) => {
            reject(error);
        };
    });
};
