<template>
    <transition name="fade">
        <div v-if="visible && isMini"
             ref="wrapper"
             :class="['visual-tooltip-mini',`is-${direction}`]"
             :style="[{'top': `${y}px`}, {'left': `${x}px`}]">
            <div class="visual-tooltip-mini__content">
                <div class="visual-tooltip-mini__name" v-html="tooltip.title" />
                <div class="visual-tooltip-mini__price">
                    {{ convertPriceDigit(tooltip.price.current) }} ₽
                </div>
            </div>
        </div>
        <div v-else-if="visible"
             ref="wrapper"
             :class="['visual-tooltip',`is-${direction}`]"
             :style="[{'top': `${y}px`}, {'left': `${x}px`}]">
            <div class="visual-tooltip__content">
                <!-- Данные планировок -->
                <div class="visual-panel__row" v-if="tooltip.isBooked">
                    <div class="visual-panel__lock">
                        <IconLock />
                    </div>
                </div>
                <div class="visual-panel__row">
                    <div class="visual-panel__option">
                        <div class="visual-panel__option-value">
                            <template v-if="tooltip.area">
                                <span class="area">{{ tooltip.area }}<span class="area-units"> м²</span></span>
                            </template>
                        </div>
                        <div class="visual-panel__option-value">
                            <span v-if="tooltip.hidePrice">По&nbsp;запросу</span>
                            <template v-else>
                                <span v-if="tooltip.price && tooltip.price.current">
                                    {{ convertPriceDigit(tooltip.price.current) }} ₽
                                </span>
                                <span v-if="tooltip.price && tooltip.price.mortgage"
                                      class="visual-panel__option-value_theme_accent">
                                            {{ convertPriceDigit(tooltip.price.mortgage) }} ₽ в месяц
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="visual-panel__row">
                    <div class="visual-panel__option">
                        <div class="visual-panel__option-value"
                             v-html="tooltip.title"></div>
                        <div v-if="tooltip.floor"
                             class="visual-panel__option-value"
                             v-html="tooltip.floor"></div>
                    </div>
                    <div v-if="tooltip.quarter"
                         class="visual-panel__option">
                        <div class="visual-panel__option-value"
                             v-html="tooltip.quarter"></div>
                        <div v-if="tooltip.inputs"
                             class="visual-panel__option-value"
                             v-html="tooltip.inputs"></div>
                    </div>
                </div>
                <div v-if="tooltip.options && tooltip.options.length"
                     class="visual-panel__row">
                    <ul class="visual-panel__options">
                        <li v-for="(option, index) in tooltip.options"
                            :key="index"
                            class="visual-panel__option-item">
                            <img alt="" :src="option">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import IconLock from '@/components/Icons/icon-lock';
import {mapGetters} from 'vuex';
import Observer from 'common/scripts/observer';
import {Utils} from 'common/scripts/utils';

const observer = new Observer();

export default {
    name: 'Tooltip',

    components: {
        IconLock
    },

    data() {
        return {
            visible  : false,
            tooltip  : false,
            direction: false,
            x        : 0,
            y        : 0
        };
    },

    computed: {
        ...mapGetters({
            dataStep: 'GET_DATA_STEP',
            isMini  : 'GET_IS_MINI'
        })
    },

    watch: {
        tooltip() {
            this.$nextTick(() => {
                this.wrapper = this.$refs.wrapper;

                this._checkBorders();
                this._setX();
                this._setY();
            });
        }
    },

    mounted() {
        observer.subscribe('mouseoverTooltip', (data) => {
            this._insert(data);
        });

        observer.subscribe('mousemoveTooltip', (event) => {
            this._change(event);
        });

        observer.subscribe('mouseleaveTooltip', () => {
            this._remove();
        });

        observer.subscribe('mouseClickTooltip', () => {
            this._remove();
        });
    },

    beforeDestroy() {
        observer.unsubscribe('mouseoverTooltip');
        observer.unsubscribe('mousemoveTooltip');
        observer.unsubscribe('mouseleaveTooltip');
        observer.unsubscribe('mouseClickTooltip');
    },

    methods: {
        _insert(data) {
            this.direction = data.direction || 'over';
            this.offset = this.isMini ? 20 : 40;

            this._getMouseCoords(data.event);
            this._setData(data);

            this.visible = true;
        },

        _change(event) {
            this._getMouseCoords(event);
            this._checkBorders();
            this._setX();
            this._setY();
        },

        _remove() {
            this.visible = false;
        },

        _getMouseCoords(event) {
            this.mouseCoords = {
                X: event.pageX,
                Y: event.pageY
            };
        },

        _setData(data) {
            const targetId = Number(data.id);

            const currentData = data.elements.find((element) => {
                const id = Number(element.id);

                return id === targetId ? element : false;
            });

            this.tooltip = {
                type    : data.type,
                modify  : data.modify,
                isSold  : currentData.isSold,
                isBooked: currentData.isBooked,
                ...currentData.tooltip
            };
        },

        _checkBorders() {
            const halfWidth = 2;

            let mouseCoord = 0;
            let tooltipCoord = 0;

            // Не влезает по верхнему краю.
            const top = () => {
                mouseCoord = this.mouseCoords.Y;
                tooltipCoord = this.wrapper.offsetHeight + this.offset;

                return mouseCoord < tooltipCoord;
            };

            // Не влезает по правому краю.
            const right = () => {
                mouseCoord = window.innerWidth - this.mouseCoords.X;
                tooltipCoord = (this.wrapper.offsetWidth / halfWidth) + this.offset;

                return mouseCoord < tooltipCoord;
            };

            // Не влезает по левому краю.
            const left = () => {
                mouseCoord = this.mouseCoords.X;
                tooltipCoord = (this.wrapper.offsetWidth / halfWidth) - this.offset;

                return mouseCoord < tooltipCoord;
            };

            if (top()) {
                this.direction = 'under';
            }

            if (right()) {
                this.direction = 'left';
            }

            if (left()) {
                this.direction = 'right';
            }
        },

        _setX() {
            const halfWidth = 2;

            switch (this.direction) {
                case 'over':
                    this.x = this.mouseCoords.X - (this.wrapper.offsetWidth / halfWidth);
                    break;
                case 'under':
                    this.x = this.mouseCoords.X - (this.wrapper.offsetWidth / halfWidth);
                    break;
                case 'left':
                    this.x = this.mouseCoords.X - this.wrapper.offsetWidth - this.offset;
                    break;
                case 'right':
                    this.x = this.mouseCoords.X + this.offset;
                    break;
                default:
                    this.x = this.mouseCoords.X - (this.wrapper.offsetWidth / halfWidth);
                    break;
            }
        },

        _setY() {
            const halfHeight = 2;

            switch (this.direction) {
                case 'over':
                    this.y = this.mouseCoords.Y - this.wrapper.offsetHeight - this.offset;
                    break;
                case 'under':
                    this.y = this.mouseCoords.Y + this.offset;
                    break;
                case 'left':
                    this.y = this.mouseCoords.Y - (this.wrapper.offsetHeight / halfHeight);
                    break;
                case 'right':
                    this.y = this.mouseCoords.Y - (this.wrapper.offsetHeight / halfHeight);
                    break;
                default:
                    this.y = this.mouseCoords.Y - this.wrapper.offsetHeight - this.offset;
                    break;
            }
        },

        convertPriceDigit(price) {
            return Utils.convertToDigit(price);
        }
    }
};
</script>

<style lang="scss">
    @import "./Tooltip.scss";
    @import "./TooltipMini.scss";
</style>
