<template>
    <div class="visual-touch-clue">
        <div class="visual-touch-clue__text"
             v-html="'Тяните вбок,<br>чтобы перемещаться<br>по&nbsp;генплану'"></div>
    </div>
</template>

<script>
export default {
    name: 'TouchClue'
};
</script>

<style lang="scss">
    @import "TouchClue";
</style>
