<template>
    <div class="visual-zoom">
        <svg class="visual-zoom__canvas" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:ev="http://www.w3.org/2001/xml-events">
            <g>
                <slot />
            </g>
        </svg>
        <!-- Зум -->
        <div class="visual-zoom__actions">
            <button class="visual-canvas__zoom-plus" @click="zoomPlus">+</button>
            <button class="visual-canvas__zoom-minus" @click="zoomMinus">-</button>
        </div>
    </div>
</template>

<script>
import panZoom from 'panzoom';
import {Utils} from '../../../../src/common/scripts/utils';

export default {
    name: 'Zoom',

    data() {
        return {
            maxZoom       : 4,
            minZoom       : 1,
            // Текущая сумма зума
            zoom          : 0,
            // Модуль зума
            zoomModule    : null,
            // Блок который будем увеличивать/уменьшать
            magnifiedBlock: null,
            isMobile      : false
        };
    },

    mounted() {
        const element = document.querySelector('.visual-zoom__canvas');

        this.initZoom(element);
    },

    methods: {
        /**
         * Инициализация зума
         * @param {element} wrapper - обёртка для зума
         */
        initZoom(wrapper) {
            this.isMobile = Utils.isMobile();

            const settings = {
                maxZoom             : this.maxZoom,
                minZoom             : this.minZoom,
                zoomDoubleClickSpeed: 1,
                smoothScroll        : false,
                bounds              : true,
                boundsPadding       : this.isMobile ? 0.3 : 1,
                beforeWheel         : () => {
                    return true;
                },
                onDoubleClick: () => {
                    return true;
                },
                onTouch: () => {
                    return false;
                }
            };

            if (this.isMobile) {
                settings.initialX = -1200;
                settings.initialY = 0;
                settings.initialZoom = 1.1;
            }

            this.zoomModule = panZoom(wrapper, settings);

            this.magnifiedBlock = wrapper.querySelector('g');
        },

        zoomPlus() {
            if (this.zoom >= this.maxZoom && !this.isMobile) {
                this.zoom = this.maxZoom;

                return;
            }

            this.changeZoom(true);
        },

        zoomMinus() {
            const minZoomMultiplier = 0;

            if (this.zoom <= minZoomMultiplier && !this.isMobile) {
                this.zoom = minZoomMultiplier;

                return;
            }

            this.changeZoom(false);
        },

        /**
         * Изменение текущего зума
         * @param {boolean} isZoom - увеличиваем/уменьшаем
         */
        changeZoom(isZoom) {
            if (this.isMobile) {
                this.zoomModule.moveTo(0, 0);
            }

            const rect = this.magnifiedBlock.getBBox();
            const cx = rect.x + (rect.width / 2);
            const cy = rect.y + (rect.height / 2);
            const zoomBy = isZoom ? 2 : 0.5;

            this.zoom = isZoom ? this.zoom + 2 : this.zoom - 2;

            this.zoomModule.zoomTo(cx, cy, zoomBy);

            if (this.zoom <= this.minZoom) {
                this.setDefaultPosition();
            }
        },

        /**
         * Устанавливает блок по центру
         */
        setDefaultPosition() {
            if (this.isMobile) {
                return;
            }

            this.zoomModule.moveTo(0, 0);
        }
    }
};
</script>

<style lang="scss">
@import "Zoom";
</style>
